<script lang="ts">
	import { field, form } from 'svelte-forms';
	import { email, min, required } from 'svelte-forms/validators';
	import FormFieldTextInput from '$lib/components/Form/FormFieldTextInput.svelte';
	import { getTranslate } from '@tolgee/svelte';
	import { customerSessionDrawer } from '$lib/store/customerSessionDrawer.store';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import { get } from 'svelte/store';
	import { customerSessionStore } from '$lib/store/customerSession.store';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { resolveRoute } from '$app/paths';
	import FormFieldCheckbox from '$lib/components/Form/FormFieldCheckbox.svelte';
	import { NOTIFICATION, notificationStore } from '$lib/store/notification.store';

	export let externalEmail: string | undefined = undefined;

	const emailField = field('email', '', [required(), email()]);
	const passwordField = field('password', '', [required(), min(8)]);
	const acceptsMarketing = field('acceptsMarketing', true);
	$: formData = externalEmail
		? form(passwordField, acceptsMarketing)
		: form(emailField, passwordField, acceptsMarketing);
	let isLoading = false;
	let fetchErrors = [];

	const { t } = getTranslate();

	const onSubmit = async () => {
		await formData.validate();
		if ($formData.valid) {
			isLoading = true;
			try {
				await customerSessionStore.register(
					externalEmail ?? $emailField.value,
					$passwordField.value
				);
				customerSessionDrawer.setIsExpanded(false);
				notificationStore.addNotification({
					actions: [
						{
							href: '/customer/profile/edit',
							text: 'Profil bearbeiten'
						}
					],
					text: $t('modals.signUp.text'),
					title: $t('modals.signUp.title'),
					variant: NOTIFICATION.SUCCESS
				});
				await goto(
					resolveRoute('/[locale]-[country]/customer', {
						...get(page).params
					})
				);
			} catch (e) {
				if (Array.isArray(e)) {
					fetchErrors = e;
				} else {
					throw e;
				}
			}
			isLoading = false;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit} class="flex flex-col gap-6">
	{#if !externalEmail}
		<FormFieldTextInput
			label={$t('customer.email')}
			bind:value={$emailField.value}
			errors={$formData.errors}
			name="email"
			type="text"
		/>
	{/if}
	<FormFieldTextInput
		label={$t('changeEmail.form.password')}
		bind:value={$passwordField.value}
		errors={$formData.errors}
		name="password"
		type="password"
	/>
	{#if fetchErrors.length}
		<div class="text-special-danger">
			{#each fetchErrors.filter((e) => !!e.message) as err}
				<div>{err.message}</div>
			{/each}
		</div>
	{/if}
	<FormFieldCheckbox
		name="acceptsMarketing"
		bind:checked={$acceptsMarketing.value}
		errors={$formData.errors}
	>
		<p>
			{$t('signUp.acceptsMarketing')}
		</p>
	</FormFieldCheckbox>
	<p class="text-p-12 text-gray-500">
		{$t('signUp.privacy')}
	</p>
	<div class="flex justify-end">
		<ButtonPrimary
			class="w-full"
			type="submit"
			loading={isLoading}
			text={$t('signUp.submitButton')}
		/>
	</div>
</form>
